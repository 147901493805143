var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "v-container",
        { staticClass: "px-6 py-6", attrs: { fluid: "" } },
        [
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _c("v-row", [
                    _c(
                      "div",
                      {
                        staticClass: "card-header-padding",
                        staticStyle: {
                          "padding-top": "0px",
                          "padding-bottom": "0px",
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "d-flex justify-center align-center" },
                          [
                            _c(
                              "p",
                              {
                                staticClass:
                                  "text-sm font-weight-light text-body mb-0",
                              },
                              [_vm._v("Showing metrics for")]
                            ),
                            _c(
                              "v-icon",
                              {
                                attrs: { disabled: _vm.loading },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeMetricsEndDate("-")
                                  },
                                },
                              },
                              [_vm._v(" chevron_left ")]
                            ),
                            _c(
                              "v-menu",
                              {
                                ref: "menu",
                                attrs: {
                                  "close-on-content-click": false,
                                  transition: "scale-transition",
                                  "offset-y": "",
                                  "min-width": "4px",
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "activator",
                                    fn: function (ref) {
                                      var on = ref.on
                                      var attrs = ref.attrs
                                      return [
                                        _c(
                                          "v-text-field",
                                          _vm._g(
                                            _vm._b(
                                              {
                                                staticClass:
                                                  "custom centered-input mt-3",
                                                attrs: { readonly: "" },
                                                model: {
                                                  value:
                                                    _vm.displayMetricsEndDate,
                                                  callback: function ($$v) {
                                                    _vm.displayMetricsEndDate =
                                                      $$v
                                                  },
                                                  expression:
                                                    "displayMetricsEndDate",
                                                },
                                              },
                                              "v-text-field",
                                              attrs,
                                              false
                                            ),
                                            on
                                          )
                                        ),
                                      ]
                                    },
                                  },
                                ]),
                                model: {
                                  value: _vm.menu,
                                  callback: function ($$v) {
                                    _vm.menu = $$v
                                  },
                                  expression: "menu",
                                },
                              },
                              [
                                _c(
                                  "v-date-picker",
                                  {
                                    attrs: {
                                      "no-title": "",
                                      scrollable: "",
                                      color: "pink",
                                      "show-adjacent-months": "",
                                    },
                                    model: {
                                      value: _vm.selectDate,
                                      callback: function ($$v) {
                                        _vm.selectDate = $$v
                                      },
                                      expression: "selectDate",
                                    },
                                  },
                                  [
                                    _c("v-spacer"),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { text: "", color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            _vm.menu = false
                                          },
                                        },
                                      },
                                      [_vm._v(" Cancel ")]
                                    ),
                                    _c(
                                      "v-btn",
                                      {
                                        attrs: { text: "", color: "primary" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectMetricsEndDate(
                                              _vm.selectDate
                                            )
                                          },
                                        },
                                      },
                                      [_vm._v(" OK ")]
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                            _c(
                              "v-icon",
                              {
                                attrs: { disabled: _vm.loading },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeMetricsEndDate("+")
                                  },
                                },
                              },
                              [_vm._v(" chevron_right ")]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "v-row",
            [
              _c(
                "v-col",
                { attrs: { cols: "12" } },
                [
                  _vm.loading
                    ? _c(
                        "h4",
                        [
                          _c("v-progress-circular", {
                            attrs: { indeterminate: "" },
                          }),
                        ],
                        1
                      )
                    : _c("site-container", {
                        attrs: {
                          checkFrequenciesToDisplay:
                            _vm.checkFrequenciesToDisplay,
                          componentProps: _vm.componentProps,
                        },
                      }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }