<template>
  <div>
    <v-container fluid class="px-6 py-6">
      <v-row>
        <v-col cols="12">
          <v-row>
            <div class="card-header-padding" style="padding-top: 0px; padding-bottom: 0px;">
              <div class="d-flex justify-center align-center">
                <p class="text-sm font-weight-light text-body mb-0">Showing metrics for</p>
                <v-icon :disabled="loading" @click="changeMetricsEndDate('-')">
                  chevron_left
                </v-icon>
                <v-menu
                  ref="menu"
                  v-model="menu"
                  :close-on-content-click="false"
                  transition="scale-transition"
                  offset-y
                  min-width="4px"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="displayMetricsEndDate"
                      readonly
                      v-bind="attrs"
                      v-on="on"
                      class="custom centered-input mt-3"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="selectDate"
                    no-title
                    scrollable
                    color="pink"
                    show-adjacent-months
                  >
                    <v-spacer></v-spacer>  
                    <v-btn
                      text
                      color="primary"
                      @click="menu = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn
                      text
                      color="primary"
                      @click="selectMetricsEndDate(selectDate)"
                    >
                      OK
                    </v-btn>
                  </v-date-picker>
                </v-menu>
                    <!-- {{ displayMetricsEndDate }} -->
                <v-icon :disabled="loading" @click="changeMetricsEndDate('+')">
                  chevron_right
                </v-icon>
              </div>
            </div>
          </v-row>
        </v-col>
      </v-row>  
      <!--
      <v-row class="mb-6 mt-15">
        <v-col lg="4" class="pt-0 mb-lg-0 mb-10">
          <card-thirty-day-health :metricsEndDateMoment="metricsEndDateMoment"></card-thirty-day-health>
        </v-col>
        <v-col lg="4" class="pt-0 mb-lg-0 mb-10">
          <card-thirty-day-perf :metricsEndDateMoment="metricsEndDateMoment"></card-thirty-day-perf>
        </v-col>
        <v-col lg="4" class="pt-0 mb-lg-0">
          <card-open-cases></card-open-cases>
        </v-col>
      </v-row>
      -->
      <!-- <v-row>
        <v-col md="12">
          <v-row>
            <v-col sm="3" cols="12">
              <high-alert-site v-if="checkFrequenciesToDisplay"
                :checkFrequenciesToDisplay="checkFrequenciesToDisplay" 
                :metricsEndDateMoment="metricsEndDateMoment"
                @clicked="showHighAlertSites"
                @loading="finishedLoading">
              </high-alert-site>
            </v-col>
            <v-col sm="3" cols="12">
              <medium-alert-site v-if="checkFrequenciesToDisplay"
                :checkFrequenciesToDisplay="checkFrequenciesToDisplay" 
                :metricsEndDateMoment="metricsEndDateMoment"
                @clicked="showMediumAlertSites">
              </medium-alert-site>
            </v-col>
            <v-col sm="3" cols="12">
              <all-sites v-if="checkFrequenciesToDisplay"
                :checkFrequenciesToDisplay="checkFrequenciesToDisplay" 
                :metricsEndDateMoment="metricsEndDateMoment"
                @clicked="showAllSites">
              </all-sites>
            </v-col>
            <v-col sm="3" cols="12">
              <card-missing-kwh-data :metricsEndDateMoment="metricsEndDateMoment">
              </card-missing-kwh-data>
            </v-col>
          </v-row>
        </v-col>
      </v-row> -->
      <v-row >
        <v-col cols="12">
          <h4 v-if="loading">
          <v-progress-circular
                    indeterminate
                  ></v-progress-circular>
          </h4>
          <site-container v-else v-bind:checkFrequenciesToDisplay="checkFrequenciesToDisplay" v-bind:componentProps="componentProps"></site-container>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import SalesCountry from "./Widgets/SalesCountry.vue";
import CardWebsiteViews from "./Widgets/CardWebsiteViews.vue";
import CardDailySales from "./Widgets/CardDailySales.vue";
import CardCompletedTasks from "./Widgets/CardCompletedTasks.vue";
import HighAlertSite from './Widgets/HighAlertSite.vue';
import CardOpenCases from './Widgets/CardOpenCases.vue';
import DatatableSites from '../Applications/Widgets/DatatableSites.vue';
import SiteContainer from './Site/SiteContainer';
import CardThirtyDayHealth from './Widgets/CardThirtyDayHealth.vue';
import CardThirtyDayPerf from './Widgets/CardThirtyDayPerf.vue';
import ProblemInverter from './Widgets/ProblemInverter.vue';
import CardMissingKwhData from './Widgets/CardMissingKwhData.vue';
import MediumAlertSite from './Widgets/MediumAlertSite.vue';
import AllSites from './Widgets/AllSites.vue';
import Service from "@/services/Service.js";

export default {
  name: "Dashboard",
  components: {
    SalesCountry,
    CardWebsiteViews,
    CardDailySales,
    CardCompletedTasks,
    HighAlertSite,
    CardOpenCases,
    DatatableSites,
    CardThirtyDayHealth,
    CardThirtyDayPerf,
    ProblemInverter,
    CardMissingKwhData,
    SiteContainer,
    MediumAlertSite,
    AllSites
  },
  beforeMount() {
    
  },
  data() {
    return {
      selectDate: null,
      everySite: null,
      componentProps: {},
      loading: false,
      componentName: "DatatableSites",
      checkFrequenciesToDisplay: null,
      displayAllCheckFrequencies: false,
      yesterdayMoment: null,
      metricsEndDateMoment: null,
      displayMetricsEndDate: null,
      allCheckFrequencies: [
        {
          value: "daily",
          text: "Daily",
        },
        {
          value: "weekly",
          text: "Weekly",
        },
        {
          value: "monthly",
          text: "Monthly",
        },
      ],
      highAlerts: {},
      mediumAlerts: {},
    };
  },
  created: function () {
    // need to read users threshold settings for alerts
    // TODO: read this on computed not at create time

    this.highAlerts = this.$store.state.alerts.highAlerts[0];
    this.mediumAlerts = this.$store.state.alerts.mediumAlerts[0];
    console.log('Dashboard -- created');
    console.log(this.mediumAlerts);

    this.yesterdayMoment = this.$moment().subtract(1, "days");
    this.metricsEndDateMoment = this.$moment().subtract(1, "days");
    this.displayMetricsEndDate = this.metricsEndDateMoment.format("MM/DD/YYYY");
    this.checkFrequenciesToDisplay = this.defaultCheckFrequenciesToDisplay();
    // TODO: we need to load checks for this user and store them in vuex store to be used for calcs
    // const dailyChecks = [
    //   {
    //     "date": "2022-07-22",
    //     "user_id": 1,
    //     "check_type_id": 1, 
    //   },
    // ];
    // this.$store.dispatch('setChecks', dailyChecks)
    // this.$store.commit('foo', 1);
    this.loadComboMetrics();
  },
  mounted() {
    document.title = "Monitoring Portal"
  },
  methods: {
    changeMetricsEndDate(plusOrMinusSign) {
      
      this.dateKey += 1;
      if (plusOrMinusSign === "-") {
        // create new object to trigger child components
        const d = this.$moment(this.metricsEndDateMoment).subtract(1, "days");
        this.metricsEndDateMoment = d;
        // re-calc frequencies to display
        this.checkFrequenciesToDisplay = this.defaultCheckFrequenciesToDisplay();
        this.componentProps = {};
        this.loadComboMetrics();
      } else {
        if (!this.metricsEndDateMoment.isSameOrAfter(this.yesterdayMoment)) {
          const d = this.$moment(this.metricsEndDateMoment).add(1, "days");
          this.metricsEndDateMoment = d;
          // re-calc frequencies to display
          this.checkFrequenciesToDisplay = this.defaultCheckFrequenciesToDisplay();
          this.componentProps = {};
          this.loadComboMetrics();
        }
      }

      this.displayMetricsEndDate = this.metricsEndDateMoment.format(
        "MM/DD/YYYY"
      );
      // this.setDefaultCheckFrequenciesToDisplay();
      // this.setCheckedToday();
    },
    selectMetricsEndDate(date) {
      const c = this.$moment(date)
      this.metricsEndDateMoment = c
      this.checkFrequenciesToDisplay = this.defaultCheckFrequenciesToDisplay();
      this.componentProps = {};
      this.loadComboMetrics();
      this.displayMetricsEndDate = this.metricsEndDateMoment.format(
        "MM/DD/YYYY"
      );
      this.selectDate = this.metricsEndDateMoment
    },
    defaultCheckFrequenciesToDisplay() {
      const metricsEndDateIsFirstOfMonth =
        this.metricsEndDateMoment.date() === 1;
      const metricsEndDateIsFirstOfWeek = this.metricsEndDateMoment.day() === 3;

      let checkFrequencies = ["daily"];

      if (metricsEndDateIsFirstOfMonth) {
        checkFrequencies.push("monthly");
      }

      if (metricsEndDateIsFirstOfWeek) {
        checkFrequencies.push("weekly");
      }

      return checkFrequencies;
    },
    loadComboMetrics() {
      const dateString = this.metricsEndDateMoment.format("YYYY-MM-DD");
      this.loading = true;
      const cf = this.checkFrequenciesToDisplay
      Service.getSitesGrid()
        .then((response) => {
          this.everySite = response.data;
        })
        .then((response) => {
          Service.getDashboardMetrics(dateString)
            .then((response) => {
              const sm = response[0].data.site_metrics;
              const site_metrics = this.adjustDailyHealth(sm);
              const raw_inverter_metrics = response[1].data.inverter_metrics;
              //add monitoring_url to inverter_metrics
              const inverter_metrics = raw_inverter_metrics.map(inverter => {
                  const site = this.everySite.find(site => site.id === inverter.site_id);
                  return {
                      ...inverter,
                      master_id: site ? site.master_site_id : null,
                      monitoring_system: site ? site.monitoring_system : null,
                      monitoring_url: site ? site.monitoring_url : null
                  };
              });
              const user_checks = response[2].data;
              this.$store.dispatch('setChecks', user_checks)
              // break master props up into 4 groups: daily, weekly, monthly, and all
              const daily = site_metrics.filter((s) => {
                return (s.check_frequency == 'daily' && s.is_paused == false);
              });
              console.log(`found ${daily.length} daily sites`);
              // daily high
              const daily_high = this.getHighAlertSites(daily, inverter_metrics);
              console.log(`found ${daily_high.length} daily high alert sites`);
              // daily medium
              const daily_medium = this.getMediumAlertSites(daily_high, daily, inverter_metrics);
              console.log(`found ${daily_medium.length} daily medium alert sites`)
              // weekly
              const weekly = site_metrics.filter((s) => {
                return (s.check_frequency == 'weekly' && s.is_paused == false);
              });
              // weekly high
              const weekly_high = this.getHighAlertSites(weekly, inverter_metrics);
              console.log(`found ${weekly_high.length} weekly high alert sites`);
              // daily medium
              const weekly_medium = this.getMediumAlertSites(weekly_high, weekly, inverter_metrics);
              console.log(`found ${weekly_medium.length} weekly medium alert sites`)

              const monthly = site_metrics.filter((s) => {
                return (s.check_frequency == 'monthly' && s.is_paused == false);
              });
              // monthly high
              const monthly_high = this.getHighAlertSites(monthly, inverter_metrics);
              console.log(`found ${monthly_high.length} monthly high alert sites`);
              // monthly medium
              const monthly_medium = this.getMediumAlertSites(monthly_high, monthly, inverter_metrics);
              console.log(`found ${monthly_medium.length} monthly medium alert sites`)
              // all
              const all = site_metrics;

              // console.log(`found ${ps.length} total sites for check frequency: ${cf}`)
              this.componentProps = {
                date: dateString,
                problem_sites: site_metrics,
                daily: daily,
                daily_high: daily_high,
                daily_medium: daily_medium,
                weekly: weekly,
                weekly_high: weekly_high,
                weekly_medium: weekly_medium,
                monthly: monthly,
                monthly_high: monthly_high,
                monthly_medium: monthly_medium,
                all: all,
                inverter_metrics: inverter_metrics
              };
              return Service.getSelectedComponents();
              
            })
            .then((response) => {
              const selectedComponents = response.data.data;
              this.componentProps.selectedComponents = selectedComponents;
              this.loading = false;
            })
            .catch((error) => {
              console.log("Error getting metric data:", error.response);
              this.loading = false;
            });
        })
        .catch((error) => {
          console.log("Error getting metric data:", error.response);
          this.loading = false;
        });
    },
    adjustDailyHealth(sm) {
      const ps = sm.map((s) => {
        // Check if the site has cases
        if (s.has_cases !== 0) {
          // If it has cases, add case_pct_of_dc_size_kw to day_health
          return {
            ...s, // Spread operator to copy all properties of the site
            day_health: s.day_health + s.case_pct_of_dc_size_kw
          };
        } else {
          // If it doesn't have cases, return the site as is
          return s;
        }
      });
      return ps;
    },
    getHighAlertSites(sites, inverters) {
      let highThresholdSites = this.highAlerts.highThresholdSites;
      let highThresholdInverters = this.highAlerts.highThresholdInverters;
      let highThresholdInsite = this.highAlerts.highThresholdInsite;
      //
      const ip = inverters.filter((i) => {
          // check for 0 or do the pct based on solcast health threshold
          //return (i.kwh == 0) ;//|| (Math.round(i.day_health) < highThresholdInverters);
          if(i.kwh) {
            if((i.kwh == 0) || (Math.round(i.day_health) < highThresholdInverters) || (Math.round(i.q_percentage) < highThresholdInsite) ) {
              return true;
            } else {
              return false;
            }
          } else {
            return true;
          }
      });

        // Flag inverter issue
      const ps = sites.map((s) => {
        let inv_issue = ip.find(i => i.site_id == s.id);
        if(inv_issue) {
          s.inv_issue = true;
          return s;
        } else {
          s.inv_issue = false;
          return s;
        }
      });
      
      const list = ps.filter((s) => {
        // site level issue or inverter issue or we're below the user defined threshold for high alerts for sites
        //
        if(s.kwh) {
          if(s.kwh == 0 || (Math.round(s.day_health) < highThresholdSites) || s.inv_issue) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
      return list;
    },
    getMediumAlertSites(high, sites, inverters) {
      let mediumThresholdSites = this.mediumAlerts.mediumThresholdSites;
      let mediumThresholdInverters = this.mediumAlerts.mediumThresholdInverters;
      let mediumThresholdInsite = this.mediumAlerts.mediumThresholdInsite;
      
      // const ip = inverters.filter((i) => {
      //   return (i.thirty_day_health < 60 || i.day_health < 85 || i.kwh <= 0);
      // });
      const ip = inverters.filter((i) => {
        // return (i.q_percentage < mediumThresholdInsite || i.kwh <= 0);
        if(i.kwh) {
          if((i.kwh <= 0) || (Math.round(i.day_health) < mediumThresholdInverters) || (Math.round(i.q_percentage) < mediumThresholdInsite) ) {
            return true;
          } else {
            return false;
          }
        } else {
          return true;
        }
      });
      const ps = sites.map((s) => {
        let inv_issue = ip.find(i => i.site_id == s.id);
        if(inv_issue) {
          s.inv_issue = true;
          return s;
        } else {
          s.inv_issue = false;
          return s;
        }
      });  
      
      const list = ps.filter((s) => {
        // site level issue or inverter issue
        const found = high.find((h) => h.id == s.id);
        
        if(s.kwh) {
          return (s.day_health < mediumThresholdSites || s.inv_issue) && !found;
        } else {
          return true && !found;
        }
        // return (s.thirty_day_health < 60 || s.day_health < 85 || s.inv_issue) && !found;
        
      });
      return list;
    },
    showHighAlertSites(value) {
      // set props for child datatable
      this.componentProps = value;
      // console.log('dashboard -- show problem sites');
    },
    finishedLoading(value) {
      // set props for child datatable
      this.loading = value;
      // console.log('dashboard -- show problem sites');
    },
    showMediumAlertSites(value) {
      this.componentProps = value;
    },
    showAllSites(value) {
      this.componentProps = value;
    }
  },
};
</script>
<style>
.custom.v-text-field>.v-input__control>.v-input__slot:before {
    border-style: none;
}
</style>
<style scoped>
.centered-input >>> input {
  text-align: center;
}
</style>