<template>
  <v-card class="mb-6 card-shadow border-radius-xl py-4">
    <v-row no-gutters class="px-4">
      <v-col sm="4">
        <v-avatar
          color="bg-gradient-success border-radius-xl mt-n8"
          class="shadow-success"
          height="64"
          width="64"
        >
          <v-icon class="material-icons-round text-white" size="24"
            >dataset</v-icon
          >
        </v-avatar>
      </v-col>
      <v-col sm="8" class="text-end">
        <p
          class="
            text-sm
            mb-0
            text-capitalize text-body
            font-weight-light
          "
        >
          All Sites
        </p>
        <h4 v-if="loading">
          <v-progress-circular
                    indeterminate
                  ></v-progress-circular>
        </h4>
        <h4 v-else class="text-h4 text-typo font-weight-bolder mb-0">
          <v-btn class="text-h4 text-typo font-weight-bolder mb-0" @click="showProblemSites" text>
            {{site_problem_count}}
          </v-btn>
        </h4>
        
      </v-col>
    </v-row>
    <hr class="dark horizontal mt-3 mb-4" />
    <v-row class="px-4">
      <v-col cols="12">
        <p class="mb-0 text-body">
          <span class="text-success text-sm font-weight-bolder"
            >+3%</span
          >
          <span class="font-weight-light ms-1"
            >than last month</span
          >
        </p>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import Service from "@/services/Service.js";

export default {
  // props: [
  //   'checkFrequenciesToDisply',
  //   'metricsEndDateMoment'
  // ],
  props: {
    checkFrequenciesToDisplay: Array,
    metricsEndDateMoment: Object,
  },
  components: {

  },
  data: function () {
    return {
      loading: false,
      site_metrics: [],
      inverter_metrics: [],
      problem_sites: [],
      problem_inverters: [],
    };
  },
  created: function () {
    // this.metricsEndDateMoment = this.$moment().subtract(1, "days");
    this.loadComboMetrics();
  },
  beforeMount() {
    
  },
  computed: {
    site_problem_count: function() {
      if(this.problem_sites) {
        return this.problem_sites.length;
      } else {
        return 0;
      }
    },
    site_critical: function() {
      let v = this.problem_sites.filter((s) =>
          s.thirty_day_health < 50 || s.day_health < 50 
      );
      return v.length;
    },
    site_degraded: function() {
      let v = this.problem_sites.filter((s) =>
          s.thirty_day_health < 75 || s.day_health < 75 
      );
      return v.length;
    },
    site_warning: function() {
      let v = this.problem_sites.filter((s) =>
          s.thirty_day_health < 90 || s.day_health < 90
      );
      return v.length;
    }
  },
  watch: { 
    metricsEndDateMoment: function(newVal, oldVal) { // watch it
      console.log('Prop changed: ', newVal, ' | was: ', oldVal)
      console.log('reloading metrics...');
      this.loadComboMetrics();
    }
  },
  methods: {
    
    showProblemSites() {
      // emit event back to parent
      let data = {
        problem_sites: this.problem_sites,
        inverter_metrics: this.inverter_metrics
      };
      this.$emit('clicked', data);
    },
    loadComboMetrics() {
      const dateString = this.metricsEndDateMoment.format("YYYY-MM-DD");
      this.loading = true;
      // const cf = [...this.checkFrequenciesToDisplay]
      Service.getDashboardMetrics(dateString)
        .then((response) => {
          this.site_metrics = response[0].data.site_metrics;
          this.inverter_metrics = response[1].data.inverter_metrics;

          // Grab site ids for problem inverters
          // TODO: this should only be with sites of 2 inverters or less,
          // otherwise we use the cohort algorithm
          const ip = response[1].data.inverter_metrics.filter((i) => {
            return (i.thirty_day_health < 90 || i.day_health < 90);
          });

          // Flag inverter issue
          this.problem_sites = this.site_metrics.map((s) => {
            let inv_issue = ip.find(i => i.site_id == s.id);
            if(inv_issue) {
              s.inv_issue = true;
              return s;
            } else {
              s.inv_issue = false;
              return s;
            }
          });
          
        //   this.problem_sites = this.problem_sites.filter((s) => {
        //     // site level issue or inverter issue
        //     return (s.thirty_day_health < 90 || s.day_health < 90 || s.inv_issue);
        //   });
         // filter based on frequency or 0 kwh
         // remove: we show all for AllSites
        //  this.problem_sites = this.problem_sites.filter((s) => {
        //     return this.checkFrequenciesToDisplay.includes(s.check_frequency) ||
        //       s.kwh <= 0;
        //   });

          
          
          console.log(`found ${this.problem_sites.length} total sites`)
          console.log(`found ${this.problem_inverters.length} medium alert inverters`)
          this.loading = false;
        })
        .catch((error) => {
          console.log("Error getting metric data:", error.response);
          this.loading = false;
        });
    },
    
  },
}
</script>

<style>

</style>